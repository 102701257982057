<template>
  <div class="grid">
    <!-- <div class="col-12 md:col-12 xl:col-10"> -->
    <div class="col-12 xl:col-7">
      <p>
        <span class="p-input-icon-left">
          <i class="pi pi-search" />
          <InputText type="text" v-model="mcd" placeholder="Search" v-on:keydown.enter="onSearchClick()" />&nbsp;
        </span>
      </p>
      <Menu ref="menu" :model="overlayMenuItems" :popup="true" />
      <Button :label="getMenuLabel()" icon="pi pi-angle-down" @click="onToggleMenuClick" style="width: auto"/>      
      <TabView lazy class="tabview-custom" @tab-click="onPeriodTabClick($event)">
        <template v-for="period in periods" :key="period.code">
          <TabPanel>
            <template #header>
              <span>{{period.name}}</span>
            </template>
            MeigarachartController {{perf}}
            <div class="card chart1-container">
              <div class="overview-chart">
                <Chart ref="meigarachart" type="line" :data="chartBase" :options="chartOptions"></Chart>
              </div>
              <div id="chart1-tabs-container" class="chart1-tabs">
                <template v-for="(ext, index) in meigaraDataExt" :key="index">
                  <div class="chart1-tab" :class="getTabClass(index)" @click="onInfoCardClick($event)" :data-index=index>                    
                    <h4>{{ext.name}} <span v-if="currentMenuID != MENU_ESSENTIAL"></span></h4>
                    <h5 v-if="currentMenuID != MENU_R_ATRATIO && 
                      currentMenuID != MENU_NEWS_ATRATIO && 
                      currentMenuID != MENU_NEWS_HIGHLIGHT && 
                      currentMenuID != MENU_KESSAN_HIGHLIGHT">{{ext.value_last}}
                    </h5>
                    <h6 v-if="currentMenuID == MENU_NEWS_HIGHLIGHT ||
                      currentMenuID == MENU_KESSAN_HIGHLIGHT">{{ext.overview}}
                      <a v-if="ext.url != null" v-bind:href="ext.url" target="_blank">view</a>
                    </h6>
                    <span :class="getPriceClass(ext.v1d_mean, index)"
                      v-if="currentMenuID != MENU_NEWS_HIGHLIGHT &&
                      currentMenuID != MENU_KESSAN_HIGHLIGHT">{{ext.v1d_mean}}%
                    </span>
                    <h6 v-if="currentMenuID != MENU_NEWS_HIGHLIGHT &&
                      currentMenuID != MENU_KESSAN_HIGHLIGHT">平均値: {{ext.value_average}}
                    </h6>
                    <h6 v-if="currentMenuID != MENU_NEWS_HIGHLIGHT &&
                      currentMenuID != MENU_KESSAN_HIGHLIGHT">乖離率: {{ext.value_deviation}}%
                    </h6>
                    <h6 v-if="currentMenuID != MENU_ESSENTIAL &&
                      currentMenuID != MENU_NEWS_HIGHLIGHT &&
                      currentMenuID != MENU_KESSAN_HIGHLIGHT &&
                      currentMenuID != MENU_WWINDEX_RELARATIO">偏差値: {{ext.v1d_std}}
                      <span v-if="currentMenuID != MENU_ESSENTIAL">({{ext.meigara_count}}銘柄)</span>
                    </h6>
                    <h6 v-if="currentMenuID == MENU_R_RELARATIO ||
                      currentMenuID == MENU_WWINDEX_RELARATIO">相関性: {{ext.corr}}
                    </h6>
                    <h6 v-if="currentMenuID != MENU_NEWS_ATRATIO">{{ext.date}} {{ext.timestamp}}</h6>
                    <h6 v-if="currentMenuID == MENU_NEWS_ATRATIO">{{ext.sdate}} 15:00 から</h6>
                    <h6 v-if="currentMenuID == MENU_NEWS_ATRATIO">{{ext.date}} 15:00 まで</h6>
                  </div>
                </template>
              </div>
            </div>
          </TabPanel>
        </template>
      </TabView>          
    </div>
  </div>
</template>

<script>
import MeigarachartService from '@/service/MeigarachartService';
// import { Chart } from 'chart.js';
// import annotationPlugin from 'chartjs-plugin-annotation';
import CommonService from '../service/CommonService';

export default {
  data() {
    return {
      perf: null,
      MENU_ESSENTIAL: 0,
      MENU_R_ATRATIO: 1,
      MENU_R_RELARATIO: 2,
      MENU_NEWS_ATRATIO: 3,
      MENU_NEWS_HIGHLIGHT: 4,
      MENU_KESSAN_HIGHLIGHT: 5,
      MENU_WWINDEX_RELARATIO: 6,
      menuItems: ['エッセンシャル', '関係R：株価変動率', '関係R：相対株価', 'ニュース：株価変動率', 'ニュース：株価ハイライト3日', '決算：株価ハイライト15日', '主要指数：相対株価'],
      currentMenuID: 0,
      periods: [
        {name: '25日', code: '25d'},
        {name: '5日', code: '5d'},
        {name: '10日', code: '10d'},
        {name: '50日', code: '50d'},
        {name: '75日', code: '75d'},
        {name: '6ヶ月', code: '6m'},
        {name: '1年', code: '1y'},
      ],
      currentPeriodID: null,
      mcd: null, 
      currentMCD: null,
      selectedCardID: 0,
      currentCardID: 0,
      meigaraName: null,
      meigaraTitle: null,
      meigaraData: null,
      meigaraDataExt: [],
      chartOptions: null,
      chartBase: {
        labels: [],
        datasets: [
          {
            type: 'line',
            label: '終値',
            data: [],
            borderColor: ['rgba(0, 0, 0, .6)'],
            backgroundColor: ['rgba(47, 142, 229, .05)'],
            borderWidth: 1.5,
            fill: true,
            tension: .4,
            pointRadius: 3,
            pointBorderWidth: .7,
            pointBackgroundColor: ['rgba(255, 255, 255, 1)'],
            pointHoverBackgroundColor: ['#000000']
          },           
          {
            type: 'line',
            label: '5日',
            data: [],
            borderColor: ['rgba(255, 0, 255, 1)'],
            backgroundColor: ['rgba(255, 0, 255, .4)'],
            borderWidth: .5,
            fill: false,
            tension: .4,
            pointRadius: 0,
            pointBackgroundColor: ['rgba(255, 0, 255, .2)'],
          },
          {
            type: 'line',
            label: '25日',
            data: [],
            borderColor: ['rgba(0, 150, 0, 1)'],
            backgroundColor: ['rgba(0, 150, 0, .4)'],
            borderWidth: .5,
            fill: false,
            pointRadius: 0,
            tension: .4,
            pointBackgroundColor: ['rgba(0, 150, 0, .2)'],
          },
          {
            type: 'line',
            label: '75日',
            data: [],
            borderColor: ['rgba(47, 142, 229, 1)'],
            backgroundColor: ['rgba(47, 142, 229, .5)'],
            borderWidth: .5,
            fill: false,
            pointRadius: 0,
            tension: .4,
            pointBackgroundColor: ['rgba(47, 142, 229, .2)'],
          },
          {
            type: 'line',
            yAxisID: 'y2',
            label: 'レビュー',
            data: [],
            borderColor: ['rgba(255, 69, 0, 1)'],
            backgroundColor: ['rgba(255, 69, 0, .05)'],
            borderWidth: 1.2,
            fill: true,
            pointRadius: 0,
            tension: .4,
            pointBorderWidth: .7,
            pointBackgroundColor: ['rgba(255, 69, 0, .05)'],
          },
        ]
      },
      overlayMenuItems: [
        {
          label: 'エッセンシャル',
          icon: 'pi pi-star',
					command: () => {
            if(this.currentMenuID !== 0){
              this.set_reviewline_yscale('y2');                
              this.reloadChart(0, this.currentMCD);
            }
					}               
        },
        {
          separator:true
        },
        {
          label: '関係R：株価変動率',
          icon: 'pi pi-link',
					command: () => {
            if(this.currentMenuID !== 1){
              this.set_reviewline_yscale('y2');                 
              this.reloadChart(1, this.currentMCD);
            }
					}
        },        
        {
          label: '関係R：相対株価',
          icon: 'pi pi-share-alt',
					command: () => {
            if(this.currentMenuID !== 2){
              this.set_reviewline_yscale('y');  
              this.reloadChart(2, this.currentMCD);
            }
					}
        },                       
        {
          separator:true
        },        
        {
          label: 'ニュース：株価変動率',
          icon: 'pi pi-map',
					command: () => {
            if(this.currentMenuID !== 3){
              this.reloadChart(3, this.currentMCD);
              this.set_reviewline_yscale('y2');              
              //this.selectedCardID = 0;
            }
					}
        }, 
        {
          label: 'ニュース：株価ハイライト3日',
          icon: 'pi pi-sun',
					command: () => {
            if(this.currentMenuID !== 4){
              this.reloadChart(4, this.currentMCD);
              this.set_reviewline_yscale('y');
            }
					}
        },
        {
          separator:true
        }, 
        {
          label: '決算：株価ハイライト15日',
          icon: 'pi pi-calendar',
					command: () => {
            if(this.currentMenuID !== 5){
              this.reloadChart(5, this.currentMCD);
              this.set_reviewline_yscale('y');
            }
					}
        },
        {
          separator:true
        },
        {
          label: '主要指数：相対株価',
          icon: 'pi pi-book',
					command: () => {
            if(this.currentMenuID !== 6){
              this.reloadChart(6, this.currentMCD);
              this.set_reviewline_yscale('y');
            }
					}
        }
      ]
    }
  },

  meigarachartService: null,
  created() {
      this.meigarachartService = new MeigarachartService();
      this.common = new CommonService();     
      // Chart.register(annotationPlugin);     
  },

  mounted() {
    let mcd = this.$route.params.code;
    this.getChartData(0, 0, mcd); 
    this.applyChartOptions();
  },

  methods: {
    set_reviewline_yscale(pos){
      this.chartBase.datasets[4].yAxisID = pos;
    },
    reloadChart(menuID, mcd) {
      this.currentCardID = this.selectedCardID = 0;
      this.resetChart();
      this.getChartData(menuID, this.currentPeriodID, mcd);  
      this.applyChartOptions();
      this.currentMenuID = menuID;      
      this.currentMCD = mcd;
    },    
    getChartData(menuID, tabIndex, mcd) {
      this.meigarachartService.getAll(menuID, this.periods[tabIndex].code, mcd)
        .then(d => {
          if(d.data == ''){
            this.meigaraName = ' Not Found';
            return false;
          }else{
            this.meigaraName = d.data.mname;
          }

          for(let i=0; i<d.data.labels_cnt; i++) {
            this.chartBase.labels.push(d.data.labels[i]);
            if(i == d.data.labels_cnt-1){
              this.currentDate = d.data.labels[i];
            }
          }

          this.meigaraData = { 
            mcd: d.data.mcd,
            mname: d.data.mname,
            close: [],
            ma5: [],
            ma25: [],
            ma75: []
          };
          for(let i=0; i<d.data.labels_cnt; i++) {
            this.meigaraData.close.push(d.data.datasets.base.close[i]);
            this.meigaraData.ma5.push(d.data.datasets.base.ma5[i]);
            this.meigaraData.ma25.push(d.data.datasets.base.ma25[i]);
            this.meigaraData.ma75.push(d.data.datasets.base.ma75[i]);
          }

          this.meigaraDataExt = [];
          for(let i=0; i<d.data.datasets.ext_cnt; i++) {
            let sum = 0;
            this.meigaraDataExt.push(
              {
                name: d.data.datasets.ext[i].name,
                date: d.data.datasets.ext[i].date,
                sdate: d.data.datasets.ext[i].sdate,                
                timestamp: d.data.datasets.ext[i].timestamp,
                meigara_count: d.data.datasets.ext[i].meigara_count,
                v1d_mean: this.common.round_2(d.data.datasets.ext[i].v1d_mean),
                v1d_std: this.common.round_2(d.data.datasets.ext[i].v1d_std),
                overview: d.data.datasets.ext[i].overview,
                url: d.data.datasets.ext[i].url,
                value: [],
                corr: d.data.datasets.ext[i].corr
              }
            );

            let value = [];
            let last = null;
            for(let j=0; j<d.data.labels_cnt; j++) {
              value.push(d.data.datasets.ext[i].value[j]);
              sum += d.data.datasets.ext[i].value[j];              
              if(j == d.data.labels_cnt-1){
                last = d.data.datasets.ext[i].value[j];
              }
            }
            this.meigaraDataExt[i].value = value;
            if(last == null){
              this.meigaraDataExt[i].value_last = 'n/a'
            }
            else{
              this.meigaraDataExt[i].value_last = this.common.round_2(last);
              this.meigaraDataExt[i].value_average = this.common.round_2(sum / d.data.labels_cnt);
              let avg = sum / d.data.labels_cnt;
              let dr = null;
              if(menuID == this.MENU_ESSENTIAL && (i != 0 && i != 1 && i != 6 && i != 7)){ // 0:株価前日比, 1:GU, 6:配当利回り, 7:ボラティリティ
                dr = ((d.data.datasets.ext[i].value[d.data.labels_cnt-1] - avg)/Math.abs(avg))*100;
              }else{
                dr = last - avg;
              }
              this.meigaraDataExt[i].value_deviation = this.common.round_2(dr);
            }
          }

          this.chartBase.datasets[0].data = this.meigaraData.close;
          this.chartBase.datasets[1].data = this.meigaraData.ma5;
          this.chartBase.datasets[2].data = this.meigaraData.ma25;    
          this.chartBase.datasets[3].data = this.meigaraData.ma75;

          switch (this.currentMenuID) {
            case this.MENU_NEWS_HIGHLIGHT:
            case this.MENU_KESSAN_HIGHLIGHT:
              if(d.data.datasets.ext_cnt-1 < this.selectedCardID){
                this.chartBase.datasets[4].data = [];
              }else{
                if(this.currentMenuID == this.MENU_NEWS_HIGHLIGHT){
                  this.chartBase.datasets[4].data = this.getHighlightDataset(this.currentCardID, 3); 
                }else{
                  this.chartBase.datasets[4].data = this.getHighlightDataset(this.currentCardID, 15);
                }
              }
              this.chartBase.datasets[4].tension = 0;
              this.chartBase.datasets[4].backgroundColor = ['rgba(255, 69, 0, .2)'];
              this.chartBase.datasets[0].tension = 0;              
              break;
            default:
              if(d.data.datasets.ext_cnt-1 < this.selectedCardID){
                this.chartBase.datasets[4].data = [];
              }else{
                this.chartBase.datasets[4].data = this.meigaraDataExt[this.currentCardID].value;
              }
              this.chartBase.datasets[4].tension = .4;
              this.chartBase.datasets[4].backgroundColor = ['rgba(255, 69, 0, .05)'];
              this.chartBase.datasets[0].tension = .4;
          }

          this.perf = d.perf;
        }
      );
      this.currentPeriodID = tabIndex;
      this.currentMCD = mcd;
    }, 
    getMenuLabel() {
      return this.currentMCD + ' ' + this.meigaraName + ' (' + this.menuItems[this.currentMenuID] + ')';    
    },      
    resetChart() {
      this.chartBase.labels = [];
      this.meigaraData = []; 
      this.meigaraDataExt = [];
    },  
    getHighlightDataset(cardID, days){
      let value = [];
      let date = this.meigaraDataExt[cardID].date;      
      let close = this.chartBase.datasets[0].data;
      let day1 = new Date(date);
      for(let i=0; i<this.chartBase.labels.length; i++) {
        let day2 = new Date(this.chartBase.labels[i]);
        let diff = (day1 - day2)/86400000; // 差日を求める(86,400,000ミリ秒＝１日)
        if(Math.abs(diff) < days+1){
          value[i] = close[i];
        }else{
          value[i] = null;
        }
      }
      return value;
    },
    onSearchClick(){
      // if(this.mcd && this.currentMCD != this.mcd) {    
      //   this.set_reviewline_yscale('y2');
      //   this.reloadChart(0, this.mcd);
      //   this.mcd = null;
      // }

      if(this.mcd && this.currentMCD != this.mcd) {
        this.$router.push('/meigarachart/' + this.mcd);
        this.mcd = null;
      }
    },
    onToggleMenuClick(event) {
      this.$refs.menu.toggle(event);
    },      
    onPeriodTabClick(event){
      if (this.currentPeriodID != event.index) {
        this.currentPeriodID = event.index;
        this.resetChart();
        this.getChartData(this.currentMenuID, this.currentPeriodID, this.currentMCD);
        this.applyChartOptions();
      }
    },
    onInfoCardClick(event) {
      this.selectedCardID = parseInt(event.currentTarget.getAttribute('data-index'));
      if(this.selectedCardID !== this.currentCardID){
        switch (this.currentMenuID) {
          case this.MENU_NEWS_HIGHLIGHT:
            this.chartBase.datasets[4].data = this.getHighlightDataset(this.selectedCardID, 3); // +- 3 days
            break;
          case this.MENU_KESSAN_HIGHLIGHT:
            this.chartBase.datasets[4].data = this.getHighlightDataset(this.selectedCardID, 15); // +- 15 days
            break;
          default :
            this.chartBase.datasets[4].data = this.meigaraDataExt[this.selectedCardID].value;
        }
        this.applyChartOptions();
        this.currentCardID = this.selectedCardID;
      }
    },      
    getTabClass(idx){
      return [
        {'chart1-tab-active':this.selectedCardID === idx},
      ]
    },    
    getPriceClass(diff, idx) {
      return [
        {
          'positive-chart': diff > 0 && this.selectedCardID !== idx,
          'negative-chart': diff < 0 && this.selectedCardID !== idx,
          'neutral-chart': (diff == 0 || diff == null) && this.selectedCardID !== idx,
          'positive-chart-active': diff > 0 && this.selectedCardID === idx,
          'negative-chart-active': diff < 0 && this.selectedCardID === idx,
          'neutral-chart-active': (diff == 0 || diff == null) && this.selectedCardID === idx,
        }
      ];
    },    
    applyChartOptions() {
      this.chartOptions = {
        plugins: {
          title: {
            display: true,
            text: this.periods[this.currentPeriodID].name + ' チャート',
            font: {
              size: 14,
            },
            color: 'rgba(0, 0, 0, .5)',
          },
          legend: {
            display: true,
            labels: {
              color: '#A0A7B5'
            },
            position: 'right',
            align: 'start',
          },
          // annotation: {
          //   annotations: {
          //     line1: {
          //       type: 'line',
          //       yScaleID: 'y2',
          //       yMin: this.currentAverage,
          //       yMax: this.currentAverage,                
          //       borderColor: 'rgb(255, 0, 0, .7)',
          //       borderWidth: .6,
          //       label: {
          //         enabled: true,
          //         content: 'test',
          //         position: 'start',
          //         xPadding: 1,
          //         yPadding: 1
          //       }
          //     }
          //   },
          // },          
        },
        responsive: true,
        hover: {mode: 'index'},
        scales: {
          y: {
            ticks: {color: '#A0A7B5'},
            grid: {color:  'rgba(160, 167, 181, .3)'}
          },
          x: {
            ticks: {color: '#A0A7B5'},
            grid: {color:  'rgba(160, 167, 181, .3)'}
          },
          y2: {
            position: 'right',
            ticks: {color: '#A0A7B5'},
            grid: {
              grid: {color: 'rgba(160, 167, 181, .3)'},
              borderDash: [2, 3]
            }
          }          
        }
      }
    }
  }  
}
</script>

<style scoped lang="scss">
@import '../assets/prod/styles/charts.scss';
@import '../assets/prod/styles/prices.scss';
</style>

