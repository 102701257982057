export default class CommonService {
  round_2(val) {
    let ret = null;
    if(val != null){
      ret = Math.round(val * 100 + Number.EPSILON) / 100;
    }
    return ret;
  }

  getYMD(val) {
    if(val == null) {return '';}

    let date_full = new Date(val);
    let ret = null;
    let year = date_full.getFullYear();
    let month = date_full.getMonth() + 1;
    month = month.toString().length === 1 ? '0'+month : month;
    let date = date_full.getDate().toString().length === 1 ? '0'+date_full.getDate() : date_full.getDate();
    ret = year + '-' + month + '-' + date;

    return ret;
  }
}